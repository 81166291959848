.App-logo {
  height: 4rem;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

footer {
  min-height: 7rem;
  background-color: var(--bs-dark);
  color: var(--bs-white);

}

.articles {

  margin-bottom: 2rem; 
}

.article_img {
  border-radius: 0;
}

.articles-list li {
  list-style: none;
  
}

.articles-tablist {
  margin-left: 1px;
}

.card_list li:first-child div{
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card_list li:first-child div img{
  border-top: none;
  border-top-left-radius: 0;
}

.brief_artcard img {
  border-top-right-radius: 0;
}

.dropcap:first-letter {
  color: var(--bs-link-hover-color);
  float: left;
  font-family: 'Times New Roman', Georgia;
  font-size: 4.5rem;
  line-height: 3.6rem;
  padding-top: 0.2rem;
  padding-right: 8px;
  padding-left: 3px;
}

.error-text {
  font-size: 0.8rem;
  color: hsl(14, 96%, 36%);
}

#error-field {
  border: 1px solid hsl(14, 96%, 36%);
}

.char-count {
  display:inline-block;
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0.2rem 0 0 0;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.inc_vote, .dec_vote {
  color: hsl(0, 0%, 99%);
}

.inc_vote::before{ /*** replace these with fontawesome or some other icons  */
content: '\01F845'
}

.dec_vote::before{ /*** replace these with fontawesome or some other icons  */
content: '\01F847'
}

.vote_badge {
  width: 2rem;
}


.profile_link {
  display: none;
}

.delete-comment::before {
  content: '	\2715';
  color: #fff;
  font-weight:800;
}

.filter_bar hr {
  width: 98%;
  margin: 1rem auto !important;
}

.button-up::before {
  content: '\01F845';
  font-size:1rem;
  line-height:1rem;
}

.button-down::before {
  content: '\01F847';
  font-size:1rem;
  font-size:1rem;
  line-height:1rem;
}

.display-filter {
  width: 4rem;
}

.column-filter {
  max-width: 12rem;
}


.recent_list li{
  background-color: var(--bs-gray-200);
  border-radius: 0.3rem;
}

.recent_list li:nth-child(even){ 
  background-color: var(--bs-gray-100);
}

.carousel-caption {
  background-color: hsla(0,0%,30%, 50%)
}

.not_found {
  background: url('../public/404_Launderette_Cover.jpg') no-repeat center center;
  height: 100vh;
}


.fill_height {
  min-height: 100%;
}

.messsage_box {

  background-color: hsla(0, 0%, 30%, 50%);

}

.spinner_section_lg{
  min-height: 60dvh;
}

.server_error {

  background: url('../public/oil_change_2400.jpg') no-repeat center center;
  height: 100vh;
}

.no_articles {
  background: url('../public/Searching_2400.jpg') no-repeat top center;
  background-size: auto;
  height: 80vh;
}

.user_card_img {
  height:5rem;
  width: auto;
}

.img_container_user {
  width: 8rem;
  margin-left: 1rem;
}

.user_card {
  height: 6rem;
}

.card-components {
  margin-left: 3rem;
}

.profile_avatar img {
  width: 100%;
  height: auto;
}